<template>
  <div class="sign">
    <img src="../assets/sign/top.png" class="top" alt="">
    <div class="sign-main">
      <div class="main-box">
        <div class="box-top">
          <img class="left" src="../assets/sign/sign_left.png" alt="">
          <div class="right">
            <span class="en-text">SIGN-UP</span>
            <span class="chinese-text" v-if="stepX==0">报名</span>
            <span v-else>
              <span
                  class="chinese-text">{{
                  role == 1 ? '参会嘉宾' : role == 2 ? '工作人员' : role == 4 ? '媒体' : role == 5 ? '志愿者' : ''
                }}报名</span>
            </span>

          </div>
        </div>
        <el-divider></el-divider>
        <el-form class="form" ref="loginForm" :model="form" :rules="formRules">
          <div class="form-tips">报名流程：</div>
          <div class="form-main" :class="active===2&&stepX==3?'pt_50':''">
            <el-steps :active="active" finish-status="success" :align-center="true"
                      style="margin-top: 21px;margin-bottom: 26px">
              <el-step v-for="(item,index) in stepList" :title="item.label" :key="index"></el-step>
            </el-steps>
            <div>
              <div v-if="active==0&&stepX==0">
                <el-form-item prop="enrollWay">
                  <div v-for="(item,index) in rankList" :key="index" class="rank-item"
                       @click="handleChangeSelectVal(item)">
                    <div>{{ item.label }}</div>
                    <img class="select-status" :src="index==selected?selectPng:unSelectPng" alt="">
                  </div>
                </el-form-item>
              </div>
              <div v-if="active===0&&stepX==1">
                <el-form-item prop="enrollWay">
                  <div v-for="(item,index) in types" :key="index" class="rank-item"
                       @click="handleChangeSelectVal(item)">
                    <div>{{ item.roleName }}</div>
                    <img class="select-status" :src="index==roleSelected?selectPng:unSelectPng" alt="">
                  </div>
                </el-form-item>
              </div>
              <div v-if="active===1&&stepX==2">
                <div style="margin-top: 56px"></div>
                <el-form-item label="来 源" prop="mediaSource" v-if="role==4">
                  <el-select
                      v-model="form.mediaSource"
                      placeholder="请选择来源"
                      filterable
                      @change="handleChangeStaffType"
                  >
                    <el-option
                        v-for="(item, index) in mediaSourceArr"
                        :key="index"
                        :label="item.positionName"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="工作组" prop="staffType" v-if="role==2">
                  <el-select
                      v-model="form.staffType"
                      placeholder="请选择工作组"
                      filterable
                      @change="handleChangeStaffType"
                  >
                    <el-option
                        v-for="(item, index) in staffTypeArr"
                        :key="index"
                        :label="item.positionName"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="邀请码" prop="invitationCodes" v-if="(role==2||role==4||role==5)&&flagInvitation">
                  <el-input
                      @blur="checkCode"
                      v-model="form.invitationCodes"
                      placeholder="请输入邀请码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="参会代表" prop="identity" v-if="role==1">
                  <el-select
                      v-model="form.identity"
                      placeholder="请选择参会身份"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in identityList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="参会方式" prop="joinType" v-if="role==1">
                  <el-select
                      v-model="form.joinType"
                      placeholder="请选择参会方式"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in joinTypeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="姓 名" prop="realName">
                  <el-input
                      v-model="form.realName"
                      placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="称 谓" prop="sex">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="1">男士</el-radio>
                    <el-radio :label="2">女士</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="formMobile">
                  <el-form-item label="手机号" prop="mobile">
                    <el-input
                        v-model="form.mobile"
                        placeholder="请输入手机号"
                    ></el-input>
                  </el-form-item>
                  <div class="mobile" v-if="this.selected==1">请填写被报名人员手机号</div>
                </div>

                <el-form-item label="证件类型" :prop="form.joinType!=1?'cardType':''">
                  <el-select
                      v-model="form.cardType"
                      placeholder="请选择证件类型"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in cardTypeList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号" :prop="form.joinType!=1?'idCardNo':''">
                  <el-input
                      v-model="form.idCardNo"
                      placeholder="请输入证件号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="长期居住地省" prop="province">
                  <el-select
                      v-model="form.province"
                      placeholder="请选择省"
                      filterable
                      @change="handleChangeProviceVal"
                  >
                    <el-option
                        v-for="(item, index) in provinceArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="市" prop="city">
                  <el-select
                      v-model="form.city"
                      placeholder="请选择市"
                      filterable
                      @change="handleChangeCityVal"
                  >
                    <el-option
                        v-for="(item, index) in cityArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="区" prop="region">
                  <el-select
                      v-model="form.region"
                      placeholder="请选择区"
                      filterable
                      @change="handleChangeStreet"
                  >
                    <el-option
                        v-for="(item, index) in regionArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="街道" prop="street">
                  <el-select
                      v-model="form.street"
                      placeholder="请选择街道"
                      filterable
                      @change="handleChangeStreets"
                  >
                    <el-option
                        v-for="(item, index) in streetArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="办公地点省" prop="companyProvince">
                  <el-select
                      v-model="form.companyProvince"
                      placeholder="请选择省"
                      filterable
                      @change="handleChangeCompanyProviceVal"
                  >
                    <el-option
                        v-for="(item, index) in companyProvinceArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="市" prop="companyCity">
                  <el-select
                      v-model="form.companyCity"
                      placeholder="请选择市"
                      filterable
                      @change="handleChangeCompanyCityVal"
                  >
                    <el-option
                        v-for="(item, index) in companyCityArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="区" prop="companyRegion">
                  <el-select
                      v-model="form.companyRegion"
                      placeholder="请选择区"
                      filterable
                      @change="handleChangeCompanyStreet"
                  >
                    <el-option
                        v-for="(item, index) in companyRegionArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="街道" prop="companyStreets">
                  <el-select
                      v-model="form.companyStreets"
                      placeholder="请选择街道"
                      filterable
                      @change="handleChangeStreets"
                  >
                    <el-option
                        v-for="(item, index) in companyStreetArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <!--                <el-cascader :props="cascaderProps"></el-cascader>-->


                <el-form-item label="单位类型" prop="companyType" v-if="role!=5">
                  <el-select
                      v-model="form.companyType"
                      placeholder="请选择单位类型"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in companyTypeArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="role!=5?'工作单位/组织':'学校'" prop="company">
                  <el-input
                      v-model="form.company"
                      placeholder="请输入工作单位/组织"
                  ></el-input>
                </el-form-item>

                <el-form-item label="媒体类型" prop="mediaType" v-if="role==4">
                  <el-select
                      v-model="form.mediaType"
                      placeholder="请选择媒体类型"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in mediaTypeArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="是否为院士" prop="isAcademician" v-if="role==1 && form.identity == 1">
                  <el-select
                      v-model="form.isAcademician"
                      placeholder="请选择是否为院士"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in isAcademiciansArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="院士类型" prop="academicianType" v-if="role==1 && form.identity == 1">
                  <el-checkbox-group
                      @change="handleChangeCheckboxVal"
                      v-model="academicianType"
                  >
                    <el-checkbox :disabled="academicianType.indexOf('中国科学院院士')>-1||form.isAcademician==0"
                                 label="中国工程院院士"></el-checkbox>
                    <el-checkbox
                        :disabled="(academicianType.indexOf('中国工程院院士')>-1||academicianType.indexOf('海外院士')>-1)||form.isAcademician==0"
                        label="中国科学院院士"></el-checkbox>
                    <el-checkbox :disabled="academicianType.indexOf('中国科学院院士')>-1||form.isAcademician==0"
                                 label="海外院士"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="职务" prop="post" v-if="role==1||role==2||role==4">
                  <el-input
                      v-model="form.post"
                      placeholder="请输入职务"
                  ></el-input>
                </el-form-item>
                <el-form-item label="行政级别" prop="postLevel" v-if="role==1||role==2||role==4">
                  <el-select
                      v-model="form.postLevel"
                      placeholder="请选择行政级别"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in postLevelArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="职称" prop="postTitle" v-if="role==1||role==2||role==4">
                  <el-select
                      v-model="form.postTitle"
                      placeholder="请选择职称"
                      clearable
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in postTitleArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="主宾联系方式" prop="masterPhone" v-if="role==1&&form.identity==0">
                  <el-input
                      v-model="form.masterPhone"
                      placeholder="请输入主宾联系方式进行搜索"
                  >
                    <template slot="append">
                      <div @click="getMaster" class="master-search">
                        搜索
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="masterName" label="主宾姓名" v-if="role==1&&form.identity==0">
                  <el-input
                      disabled
                      v-model="form.masterName"
                      placeholder="请输入主宾姓名"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="主宾是否是院士" prop="masterIsAcademician" v-if="role==1&&form.identity==0">
                  <el-select
                      v-model="form.masterIsAcademician"
                      placeholder="请选择职称"
                      clearable
                      filterable
                      disabled
                  >
                    <el-option
                        v-for="(item, index) in isAcademiciansArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否有随行人员" prop="haveAccompany" v-if="role==1&&form.identity==1">
                  <el-radio-group v-model="form.haveAccompany">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="form.identity == 1&&role==1&&form.haveAccompany==1">
                  <el-row
                      v-for="(item, index) in followerDtoLists"
                      :key="index"
                  >

                    <el-form-item :label="'随行人员姓名('+(index+1)+')'"
                                  class="follow-item required">
                      <el-input
                          @blur="$forceUpdate()"
                          v-model="item.followName"
                          placeholder="多人请点增加"
                          :disabled="form.haveAccompany != 1"
                          class="follower-input"
                      />
                      <el-button
                          style="margin-left: 20px"
                          type="danger"
                          icon="el-icon-delete"
                          @click="haveAccompanyDelete(index)"
                          v-if="form.identity == 1"
                          :disabled="form.haveAccompany != 1"
                      >删除
                      </el-button
                      >
                    </el-form-item
                    >

                    <el-form-item :label="'随行人员电话('+(index+1)+')'"
                                  class="required"
                    >
                      <el-input
                          @blur="handleBlurMobile"
                          v-model="item.followPhone"
                          :disabled="form.haveAccompany != 1"
                      />
                    </el-form-item
                    >

                  </el-row>
                  <div class="have-friend" @click="friendAdd">+ 添加随行人员</div>
                </div>
                <el-form-item label="志愿者身份" prop="volunteersType" v-if="role==5">
                  <el-select
                      v-model="form.volunteersType"
                      placeholder="请选择"
                      clearable
                      filterable
                      size="small"
                  >
                    <el-option
                        v-for="dict in volunteersTypeArr"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="记者证编号" v-if="role==4">
                  <el-input
                      v-model="form.pressCard"
                      placeholder="请输入记者证编号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="头像" prop="avatar">
                  <div class="avatar-tips">
                    1、正脸，不要有明显的低抬头和侧脸，且无关清晰可辩，头部须展照片面积的三分之二以上；
                  </div>
                  <div class="avatar-tips">
                    2、要求表情自然，不要有明显的闭眼和张嘴的表情；
                  </div>
                  <div class="avatar-tips">
                    3、白底，竖版，无边框，600(宽)*800(高）像素；
                  </div>
                  <div class="avatar-tips" style="margin-bottom: 16px">
                    4、文件大小在1M左右;
                  </div>
                  <el-upload
                      action="#"
                      style="width: 200px"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :http-request="ImportItem"
                      accept=".jpg, .JPG, .png, .PNG"
                      class="avatar-uploader"
                  >
                    <img v-if="form.avatar" :src="form.avatar" class="avatar"/>
                    <img v-else src="../assets/sign/carame.png" class="carame" alt="">
                  </el-upload>
                </el-form-item>

              </div>
              <div v-if="active===2&&stepX==3" class="forum-box">
                <div style="margin-top: 56px"></div>
                <div v-for="(item,index) in proDropData" :key="index" class="forum">
                  <div class="forum-left">
                    <div class="left-name">{{ item.name }}</div>
                    <div class="left-time">论坛时间：{{ item.beginTime }}至{{ item.endTime }}</div>
                    <div class="left-place">论坛地点：{{ item.placesName }}</div>
                  </div>
                  <div class="forum-right" :class="item.signUp==1?'unForum':''" @click="submeetClick(item,index)">
                    {{ item.signUp == 0 ? '报名' : '取消报名' }}
                  </div>
                </div>
              </div>
            </div>
            <!--选择交通工具-->
            <div v-if="active===2&&stepX==4">
              <div style="margin-top: 56px"></div>
              <div class="trip-tips">温馨提示：此项为非必填，如暂未确定行程给你安排，可直接点击“下一步”；后续请至“我的—来返程信息”中追加完善。</div>
              <el-form class="form" ref="mergeGuestUserForm" :model="mergeGuestUser">
                <el-form-item label="来程交通方式">
                  <el-select
                      v-model="mergeGuestUser.goTransport"
                      placeholder="来程交通方式"
                      filterable
                      clearable
                  >
                    <el-option
                        v-for="(item, index) in goTransportArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="mergeGuestUser.goTransport!=4"
                              :label="mergeGuestUser.goTransport==0?'来程航班号':mergeGuestUser.goTransport==1?'来程高铁班次':''"
                >
                  <el-input
                      v-model="mergeGuestUser.goFlight"
                      :placeholder="mergeGuestUser.goTransport==0?'请输入来程航班号':mergeGuestUser.goTransport==1?'请输入来程高铁班次':''"
                  ></el-input>
                </el-form-item>
                <el-form-item label="来程出发省份">
                  <el-select
                      v-model="mergeGuestUser.goProvince"
                      placeholder="请选择来程出发省份"
                      clearable
                      @change="handleChangeGoProvinceVal"
                  >
                    <el-option
                        v-for="(item, index) in provinceArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="来程出发城市">
                  <el-select
                      v-model="mergeGuestUser.goCity"
                      placeholder="请选择来程出发城市"
                      clearable
                  >
                    <el-option
                        v-for="(item, index) in cityArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="mergeGuestUser.goTransport!=4"
                              label="来程抵达站点"
                >
                  <el-input
                      v-model="mergeGuestUser.arriveAddress"
                      placeholder="请输入来程抵达站点"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="来程抵达时间"
                >
                  <el-date-picker
                      v-model="mergeGuestUser.goArriveTime"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选选择择来程抵达时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="mergeGuestUser.goTransport!=4"
                    label="是否需要接机"
                >
                  <el-radio-group v-model="mergeGuestUser.goNeedAirport">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="返程交通方式">
                  <el-select
                      v-model="mergeGuestUser.toTransport"
                      placeholder="返程交通方式"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in goTransportArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="mergeGuestUser.toTransport!=4"
                              :label="mergeGuestUser.toTransport==0?'返程航班号':mergeGuestUser.toTransport==1?'返程高铁班次':''"
                >
                  <el-input
                      v-model="mergeGuestUser.toFlight"
                      :placeholder="mergeGuestUser.toTransport==0?'请输入返程航班号':mergeGuestUser.toTransport==1?'请输入返程高铁班次':''"
                  ></el-input>
                </el-form-item>
                <el-form-item label="返程出发省份">
                  <el-select
                      v-model="mergeGuestUser.toProvince"
                      placeholder="请选择返程出发省份"
                      clearable
                      @change="handleChangeToProvinceVal"
                  >
                    <el-option
                        v-for="(item, index) in provinceArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="返程出发城市">
                  <el-select
                      v-model="mergeGuestUser.toCity"
                      placeholder="请选择返程出发城市"
                      clearable
                  >
                    <el-option
                        v-for="(item, index) in cityArr"
                        :key="index"
                        :label="item.cityName"
                        :value="item.cityName"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="mergeGuestUser.toTransport!=4"
                              label="返程抵达站点"
                >
                  <el-input
                      v-model="mergeGuestUser.toArriveAddress"
                      placeholder="请输入返程抵达站点"
                  ></el-input>
                </el-form-item>
                <el-form-item
                    label="返程抵达时间"
                >
                  <el-date-picker
                      v-model="mergeGuestUser.toDepartTime"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选选择择返程抵达时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="mergeGuestUser.toTransport!=4"
                    label="是否需要送机"
                >
                  <el-radio-group v-model="mergeGuestUser.toNeedAirport">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div v-if="active==3&&stepX==5">
              <div style="margin-top: 56px"></div>
              <el-form class="form" ref="guestUserSchedule" :model="guestUserScheduleForm"
                       :rules="guestUserScheduleRules">
                <el-form-item label="参会代表" prop="identity" v-if="role==1">
                  <el-select
                      v-model="form.identity"
                      disabled
                      placeholder="请选择参会身份"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in identityList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否住宿" prop="isNeedHotel">
                  <el-select
                      v-model="guestUserScheduleForm.isNeedHotel"
                      placeholder="请选择是否住宿"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in isNeedHotelArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                    v-if="guestUserScheduleForm.isNeedHotel==1"
                    label="入住时间"
                    prop="inHotelTime">
                  <el-date-picker
                      v-model="guestUserScheduleForm.inHotelTime"
                      type="date"
                      :picker-options="startTime"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="选选择入住时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                    v-if="guestUserScheduleForm.isNeedHotel==1"
                    label="离店时间"
                    prop="outHotelTime">
                  <el-date-picker
                      v-model="guestUserScheduleForm.outHotelTime"
                      type="date"
                      :picker-options="endTime"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="选选择离店时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="房间类型" prop="roomType" v-if="guestUserScheduleForm.isNeedHotel==1">
                  <el-select
                      v-model="guestUserScheduleForm.roomType"
                      placeholder="请选择房间类型"
                      filterable
                  >
                    <el-option
                        v-for="(item, index) in roomTypeArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="guestUserScheduleForm.isNeedHotel==1"
                              label="住店天数"
                              prop="stayDays">
                  <el-input
                      v-model="guestUserScheduleForm.stayDays"
                      disabled
                      placeholder="请输入来程抵达站点"
                  ></el-input>
                </el-form-item>
                <div v-if="guestUserScheduleForm.isNeedHotel==1" class="hotel-tips">提示：具体酒店安排，请与分坛工作人员进行确认</div>
              </el-form>
            </div>
            <div @click="handleChangeStep" class="dia-footer" v-if="active==0&&stepX==0">
              下一步
            </div>
            <div v-else class="footer-btn" :class="active===2&&stepX==3?'pt_241':''">
              <el-button class="btn-prev" @click="handleChangePrevStop">上一步</el-button>

              <el-button class="dia-footer" v-if="stepX!=5&&active!=3" @click="handleChangeStep">下一步</el-button>
              <el-button class="dia-footer" v-else @click="handleChangeStep">提 交</el-button>
            </div>
          </div>

        </el-form>
      </div>
      <div class="footer-text">
        技术支持：浙江云上叮咚科技有限公司
      </div>
    </div>
    <div class="back_home" @click="handleBackToHome">
      <img src="../assets/sign/right_home.png" alt="">
    </div>
    <el-dialog
        title=""
        class="info"
        :close-on-click-modal="false"
        :visible.sync="dialogMyInfoVisible"
        width="800px"
        :before-close="()=>{this.$router.push({path:'/'})}">
      <div class="info-top">
        <div>您已报名成功，请至移动端查看报名详情！</div>
        <img src="../assets/sign/dianji.png" alt="">
      </div>
      <div class="my-info">
        <div class="left">
          <div class="left-title">第二十四届中国科协年会移动端二维码</div>
          <div id="qrcode" class="qrcode_val"></div>
          <div class="left-info">为方便您查看更多报名详情，请您使用手机扫描上方二维码进入移动端个人中心查看</div>
        </div>
        <div class="right">
          <div class="right-title">移动端使用说明如下图</div>
          <img style="width: 314px; height: 278px;" src="../assets/sign/my_info.png" alt="">
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="报名邀请码"
        :visible.sync="dialogVerifyCode"
        width="510px"
        :before-close="handleClose"
    >
      <el-form ref="loginForm" :model="verifyForm" :rules="verifyRules" class="form">
        <el-form-item prop="writSunCode">
          <el-input
              placeholder="请输入邀请码，区分大小写"
              auto-complete="off"
              v-model="verifyForm.writSunCode">
          </el-input>
        </el-form-item>

      </el-form>
      <div @click="handleLoginVerifyCode" class="dia-footer">
        校 验
      </div>
    </el-dialog>
    <!--    <el-dialog-->
    <!--        title=""-->
    <!--        :visible.sync="dialogEnrollSuccess"-->
    <!--        width="510px"-->
    <!--        :before-close="()=>{this.$router.push({path:'/'})}"-->
    <!--    >-->
    <!--      <div>-->
    <!--        <img class="success-png" src="../assets/sign/success.png" alt="">-->
    <!--        <div class="success-text" @click="()=>{this.$router.push({path:'/'})}">报名成功</div>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import {
  getGuestRole,
  getPositionList,
  getUserInfo,
  queryPositionList,
  uploadImg,
  subMeetingList,
  guestConflict,
  gitCheckInvitation,
  getGuestKeyword, getGuestUserInfo, guestEnroll, selectByPosition,
  queryRegion
} from "../api";
import IdentityCodeValid from "@/lib/validation";
import {$time, $deepcopy, mobile} from '../lib/methods'
import QRCode from "qrcodejs2";

export default {
  name: "sign",
  data() {
    let validateCompanyRegion = (rule,value,callback)=>{
      if(this.form.companyRegion){
        callback()
      }else{
        callback(new Error("请选择办公地址-区"));
      }
    }
    let validateCompanyStreets = (rule,value,callback)=>{
      if(this.form.companyStreets){
        callback()
      }else{
        callback(new Error("请选择办公地址-街道"));
      }
    }
    let validateRegion = (rule,value,callback)=>{
      if(this.form.region){
        callback()
      }else{
        callback(new Error("请选择区"));
      }
    }
    let validateStreet = (rule,value,callback)=>{
      if(this.form.street){
        callback()
      }else{
        callback(new Error("请选择街道"));
      }
    }
    let validateAcademicianType = (rule, value, callback) => {
      console.log(value, 121, this.academicianType,)
      if (this.academicianType.length == 0 && this.form.isAcademician == 1) {
        callback(new Error("请选择院士类型"));
      } else if (this.form.isAcademician == 0) {
        callback()
      } else {
        callback()
      }
    }
    let validateavatar = (rule, value, callback) => {
      if (value == '') {
        callback(new Error("请上传头像"));
      } else {
        callback()
      }
    }
    let validateStayDays = (rule, value, callback) => {
      if (!this.guestUserScheduleForm.inHotelTime) {
        callback(new Error("请选择入住时间"));
      }
      if (!this.guestUserScheduleForm.outHotelTime) {
        callback(new Error("请选择离店时间"));
      }
      if (value == 0) {
        callback(new Error("共计住宿0天，请确认住宿时间是否正确！"));
      }
      callback()
    }
    let validateCard = (rule, value, callback) => {
      switch (this.form.cardType) {
        case 0:
          // 军官证
          callback();
          break;
        case 1:
          // 身份证
          if (!IdentityCodeValid(value)) {
            callback(new Error("请证件类型不对"));
          } else {
            callback();
          }
          break;
        case 3:
          callback();
          break;
        case 2:
          // 港澳回归证 和 台胞证
          callback();
          break;
        case 4:
          // 港澳回归证 和 台胞证
          callback();
          break;
        case 5:
          // 港澳回归证 和 台胞证
          callback();
          break;
      }
    };
    return {
      startTime: {
        disabledDate: time => {
          if (this.guestUserScheduleForm.outHotelTime) {
            return time.getTime() > new Date(this.guestUserScheduleForm.outHotelTime).getTime();
          }
        }
      },
      endTime: {
        disabledDate: time => {
          if (this.guestUserScheduleForm.inHotelTime) {
            return time.getTime() < new Date(this.guestUserScheduleForm.inHotelTime).getTime();
          }
        }
      },
      dialogEnrollSuccess: false,
      academicianType: [], //checkbox-group中的v-modle要单独⼀个数组对象，
      form: {
        city: '',
        street: '',
        region: '',
        province:'',
        companyProvince:'',
        companyCity:'',
        companyRegion:'',
        companyStreets:'',
        avatar: '',
        sex: 1,
        masterPhone: '',
        masterName: '',
        masterIsAcademician: '',
        invitationCodes: '',
        proDropData: [],
      },
      followerDtoLists: [
        {
          followName: undefined,
          followPhone: undefined,
        },
      ],
      regionArr: [],
      streetArr: [],
      companyProvinceArr: [],
      companyCityArr: [],
      companyRegionArr: [],
      companyStreetArr: [],
      dialogMyInfoVisible: false,
      guestUserScheduleRules: {
        isNeedHotel: [
          {required: true, message: "请选择是否需要住宿", trigger: "change"},
        ],
        inHotelTime: [
          {required: true, message: "请选择入住时间", trigger: "change"},
        ],
        outHotelTime: [
          {required: true, message: "请选择离店时间", trigger: "change"},
        ],
        roomType: [
          {required: true, message: "请选择离店时间", trigger: "change"},
        ],
        stayDays: [
          {required: true, validator: validateStayDays},
        ]
      },
      mergeGuestUser: {
        goTransport: '',
        goFlight: '',
        goProvince: '',
        goCity: '',
        arriveAddress: '',
        goArriveTime: '',
        goNeedAirport: '',
        toTransport: '',
        toFlight: '',
        toProvince: '',
        toCity: '',
        toArriveAddress: '',
        toDepartTime: '',
        toNeedAirport: ''
      },
      dialogVerifyCode: false,
      verifyForm: {
        writSunCode: ''
      },
      volunteersTypeArr: [
        {label: "带队老师", value: 0},
        {label: "学生", value: 1},
      ],
      verifyRules: {
        writSunCodeL: [
          {required: true, message: "请输入校验码", trigger: "blur"},
        ]
      },
      guestUserScheduleForm: {
        isNeedHotel: '',
        inHotelTime: '',
        outHotelTime: '',
        roomType: ''
      },
      formRules: {
        mediaSource: [
          {required: true, message: "请选择媒体来源", trigger: "change"},
        ],
        staffType: [
          {required: true, message: "请选择工作组", trigger: "change"},
        ],
        mediaType: [
          {required: true, message: "请选择媒体来源", trigger: "change"},
        ],
        invitationCodes: [
          {required: true, message: "请输入邀请码", trigger: "blur"},
        ],
        identity: [
          {required: true, message: "请选择参会身份", trigger: "change"},
        ],
        joinType: [
          {required: true, message: "请选择参会方式", trigger: "change"},
        ],
        realName: [
          {required: true, message: "请输入姓名", trigger: "blur"},
        ],
        sex: [
          {required: true, message: "请选择性别", trigger: "change"},
        ],
        mobile: [
          {required: true, message: "请输入手机号", trigger: "blur"},
        ],
        cardType: [
          {required: true, message: "请选择证件类型", trigger: "change"},
        ],
        idCardNo: [
          {required: true, message: "请输入证件号", trigger: "blur"},
          {required: true, validator: validateCard, trigger: "blur"},
        ],
        companyType: [
          {required: true, message: "请选择单位类型", trigger: "change"},
        ],
        company: [
          {required: true, message: "请输入单位", trigger: "blur"},
        ],
        isAcademician: [
          {required: true, message: "请选择是否为院士", trigger: "change"},
        ],
        academicianType: [
          {required: true, validator: validateAcademicianType, trigger: "blur"},
        ],
        post: [
          {required: true, message: "请选择职务", trigger: "change"},
        ],
        postLevel: [
          {required: true, message: "请选择行政级别", trigger: "change"},
        ],
        postTitle: [
          {required: true, message: "请选择职称", trigger: "change"},
        ],
        masterPhone: [
          {required: true, message: "请输入主宾联系方式", trigger: "blur"},
        ],
        followName: [
          {required: true, message: "请输入随行人员姓名", trigger: "blur"},
        ],
        followPhone: [
          {required: true, message: "请输入随行人员电话", trigger: "blur"},
        ],
        haveAccompany: [
          {required: true, message: "请选择是否有随行人员", trigger: "blur"},
        ],
        avatar: [
          {required: true, validator: validateavatar, trigger: "blur"},
        ],
        volunteersType: [
          {required: true, message: "请选择志愿者身份", trigger: "change"},
        ],
        province: [
          {required: true, message: "请选择省", trigger: "change"},
        ],
        city: [
          {required: true, message: "请选择市", trigger: "change"},
        ],
        region: [
          {required: true, validator: validateRegion},
        ],
        street: [
          {required: true,validator: validateStreet},
        ],
        companyProvince: [
          {
            required: true,
            message: '请选择办公地址-省',
            trigger: 'change'
          }
        ],
        companyCity: [
          {
            required: true,
            message: '请选择办公地址-市',
            trigger: 'change'
          }
        ],
        companyRegion: [
          {required: true,validator: validateCompanyRegion},
        ],
        companyStreets: [
          {required: true, validator: validateCompanyStreets},
        ],

      },
      flagInvitation: false,
      qrFlag: false,
      active: 0,      //进度条上的步骤条
      selected: 0,   //选择为自己报名或为他人报名
      roleSelected: 0,
      stepX: 0,
      collectionTrip: false,   //是否收集行程信息
      collectionStay: false,   //是否收集住宿信息
      subCode: undefined,
      selectPng: require('../assets/sign/selected.png'),
      unSelectPng: require('../assets/sign/unSelect.png'),
      provinceArr: [],
      cityArr: [],
      roomTypeArr: [
        {label: "单间", value: 1},
        {label: "标间", value: 2},
        {label: "标间拼住", value: 3},
      ],
      isNeedHotelArr: [
        {label: "是", value: 1},
        {label: "否", value: 0},
      ],
      stepList: [
        {label: '选择身份', value: 0},
        {label: '个人信息', value: 1},
        {label: '选择活动', value: 2},
        {label: '提交', value: 3}
      ],
      postLevelArr: [
        {label: '省部级及以上', value: 1},
        {label: '司局级', value: 3},
        {label: '县处级', value: 4},
        {label: '干部', value: 5},
        {label: '无', value: 0}
      ],
      postTitleArr: [
        {label: '高级职称', value: 1},
        {label: '中级职称', value: 3},
        {label: '初级职称', value: 4},
        {label: '无', value: 0},
      ],
      types: [],   /* role:1 参会代表 role:2 工作人员 role:4 媒体 role:5 志愿者 */
      rankList: [
        {label: '本人报名', value: 0},
        {label: '为他人报名', value: 1},
      ],
      role: 1,
      isAcademiciansArr: [
        {label: '是', value: 1},
        {label: '否', value: 0},
      ],
      mediaTypeArr: [
        {label: '中央主流媒体', value: 1},
        {label: '地方媒体', value: 2},
        {label: '其他媒体', value: 3},
      ],
      volunteersId: '',
      mediaSourceArr: [],
      identityList: [
        {label: '参会代表', value: 1},
        {label: '随行人员', value: 0},
      ],
      joinTypeList: [
        {label: '线上参会', value: 1},
        {label: '线下参会', value: 2},
        {label: '线上+线下参会', value: 0},
      ],
      companyTypeArr: [
        {label: "中国科协-机关部门", value: 5},
        {label: "中国科协-直属单位", value: 6},
        {label: "政府机关", value: 8},
        {label: "省科协", value: 9},
        {label: "省级科协", value: 10},
        {label: "市科协", value: 11},
        {label: "市级科协", value: 12},
        {label: "国家级学会", value: 13},
        {label: "省级学会", value: 14},
        {label: "科研机构", value: 15},
        {label: "高校", value: 16},
        {label: "医院", value: 17},
        {label: "投融资机构", value: 18},
        {label: "企业", value: 19},
        {label: "其他", value: 20},
      ],
      cardTypeList: [
        {
          label: "身份证",
          value: 1
        }, {
          label: "港澳通行证",
          value: 2
        }, {
          label: "护照",
          value: 3
        }, {
          label: "台胞证",
          value: 4,
        }, {
          label: "军官证",
          value: 0,
        },
        {
          label: "港澳台居住证",
          value: 5,
        }
      ],
      goTransportArr: [
        {
          label: "飞机",
          value: 0
        }, {
          label: "高铁",
          value: 1,
        }, {
          label: "自驾",
          value: 4,
        }
      ]
    }
  },
  mounted() {
    this.getGuestRole()
    this.getPositionList()
    this.getProviceList()

    this.queryPositionList()  //获取媒体
    this.queryPositionList1() //获取工作组
  },
  watch: {
    role(val) {
      val && val == 5 ? this.flagInvitation = true : this.flagInvitation = false
    },
    stepx(val) {
      if (val == 0) {
        this.regionArr = []
        this.cityArr = []
        this.streetArr = []
      }

    },
    'form.province'(val) {
      if (val) {
        this.handleChangeProviceVal(val)
      }
    },
    'form.companyProvince'(val) {
      if (val) {
        this.handleChangeCompanyProviceVal(val)
      }
    },
    'guestUserScheduleForm.outHotelTime'(val) {
      if (this.guestUserScheduleForm.inHotelTime && val) {
        this.guestUserScheduleForm.stayDays = $time(this.guestUserScheduleForm.inHotelTime, val)
      }
    },
    'guestUserScheduleForm.inHotelTime'(val) {
      if (this.guestUserScheduleForm.outHotelTime && val) {
        this.guestUserScheduleForm.stayDays = $time(val, this.guestUserScheduleForm.outHotelTime)
      }
    },
  },
  methods: {
    handleChangeCheckboxVal(val) {
      console.log(val, '我是返回的val')
    },
    handleClose() {
      this.verifyForm.writSunCode = undefined
      this.dialogVerifyCode = false
    },
    qrcodeRender() {
      //获取当前的ip和端口，不要写死
      this.$nextTick(() => {
        let qrcode = new QRCode('qrcode', {
          text: 'http://castmeeting.h5.2022.castmeeting.cn/#/', // url
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        });
        console.log(qrcode, 1111)
      })
    },
    handleLoginVerifyCode() {
      if (!this.verifyForm.writSunCode) {
        this.$message.error("请填写校验码");
      } else if (this.subCode == this.verifyForm.writSunCode) {
        let that = this
        that.dialogVerifyCode = false
        that.isSubPass = true
        if (that.proDropData[that.subNum].signUp == 0) {
          let postData = {
            mobile: that.form.mobile,
            subMeetingId: that.proDropData[that.subNum].id,
            type: that.selected     //0 为自己报名 1为他人报名
          }
          guestConflict(postData).then(res => {
            if (res.code == 200) {
              if (res.data.isConflict == 1) {
                let isConflict = res.data.isConflict
                this.$alert('此论坛与' + res.data.subMeetingList + "冲突,是否确认", '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  if (res.confirm) {
                    that.proDropData[that.subNum].isConflict = isConflict
                    that.proDropData[that.subNum].conflictStatus = 1
                  } else if (res.cancel) {
                    that.proDropData[that.subNum].isConflict = isConflict
                    that.proDropData[that.subNum].conflictStatus = 0
                  }
                })
              }
            }
          })
        } else {
          that.proDropData[that.subNum].signUp = that.proDropData[that.subNum].signUp == 1 ? 0 : 1
        }
        this.proDropData[this.subNum].signUp = this.proDropData[this.subNum].signUp == 1 ? 0 : 1
        this.verifyForm.writSunCode = undefined
      } else {
        this.$message.error("验证码不正确");
      }
    },
    // 点击报名
    submeetClick(data, index) {
      if (this.form.identity != 0) {
        this.subNum = index
        console.log(data)
        this.subCode = data.serialNum
        var that = this
        if (that.proDropData[index].signUp == 0) {
          that.dialogVerifyCode = true
        } else {
          that.proDropData[index].signUp = 0
        }
        this.$forceUpdate()
      } else {
        this.$message.error("随行人员不能报名/取消报名!");
      }
    },
    subMeetingList(val) {
      let params = {
        meetingId: localStorage.getItem('meetId'),
        mobile: !val ? this.form.mobile : this.form.masterPhone,
        role: val ? 1 : ''
      }
      subMeetingList(params).then(res => {
        this.proDropData = []
        res.data.forEach(item => {
          let data = {
            conflictStatus: undefined,
            haveAccompany: undefined,
            isConflict: undefined,
            isMaster: undefined,
            masterIsAcademician: undefined,
            position: 1,
            status: 0,
            id: item.id,
            subMeetingId: item.id,
            subMeetingName: item.name,
            name: item.name,
            workType: undefined,
            signUp: item.signUp,
            serialNum: item.serialNum,
            beginTime: item.beginTime,
            placesName: item.placesName,
            endTime: item.endTime,
            isStay: item.isStay,
            isWork: this.role == 4 ? 1 : undefined,
            collectionStay: item.collectionStay,
            collectionTrip: item.collectionTrip,
          }
          this.proDropData.push(data)
        })
      })
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isJPG = file.type;
      const isLt2M = file.size / 1024 < 10240;
      if (/\.(jpg|png)$/.test(isJPG)) {
        this.$message.error("上传头像图片只能是 JPG,PNG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10M!");
      }
      return isJPG && isLt2M;
    },
    ImportItem(param) {
      console.log(param, 11111111);
      var file = param.file;
      var formData = new FormData();
      formData.append("file", file);
      uploadImg(formData).then((res) => {
        if (res.code == 200) {
          this.$message({type: "success", message: "上传成功"});
          this.form.avatar = res.data

        } else {
          this.$message({type: "error", message: "上传失败，重新上传"});
          this.form.avatar = ''

        }
        this.$forceUpdate()
      })
    },
    haveAccompanyDelete(index) {
      if (this.followerDtoLists.length > 1) {
        this.followerDtoLists.splice(index, 1);
      } else {
        this.followerDtoLists = [
          {
            followName: undefined,
            followPhone: undefined,
          },
        ];
      }
    },
    // 增加随行人员
    friendAdd() {
      if (this.form.identity == 1 && this.form.haveAccompany == 1) {
        if (this.followerDtoLists.length < 5) {
          let obj = {
            followName: undefined,
            followPhone: undefined,
          };

          this.followerDtoLists.push(obj);
          this.$forceUpdate()

        } else {
          this.$message({
            showClose: true,
            message: "您最多可添加5个随行人员",
            type: "warning",
          });
        }
      }
    },
    //获取媒体来源
    queryPositionList() {
      let params = {
        meetingId: localStorage.getItem('meetId'),
        roleType: 4
      }
      queryPositionList(params).then(res => {
        this.mediaSourceArr = res.data
      })
    },
    //获取工作组来源
    queryPositionList1() {
      let params = {
        meetingId: localStorage.getItem('meetId'),
        roleType: 2
      }
      queryPositionList(params).then(res => {
        this.staffTypeArr = res.data
      })
    },

    //获取省
    getProviceList() {
      this.form.city = undefined
      this.form.region = undefined
      this.form.street = undefined
      this.cityArr = []
      queryRegion().then((response) => {
        this.provinceArr = response.data
        this.companyProvinceArr = response.data
      })
    },
    handleChangeStreets(val) {
      if (val) {
        console.log(val,'我是返回的val')
        this.$forceUpdate()
      }
    },
    handleChangeStreet(val) {
      this.form.street = undefined
      let code
      console.log(val, '我是返回的值')
      this.regionArr.forEach(e => {
        if (e.cityName == val) {
          code = e.code
        }
      })
      queryRegion({superId: code}).then((response) => {
        this.streetArr = response.data
      })
    },
    handleChangeCompanyCityVal(val){
      this.form.companyRegion = undefined
      this.form.companyStreets = undefined
      this.companyStreetArr = []
      this.companyRegionArr = []
      let code
      this.companyCityArr.forEach(e => {
        if (e.cityName == val) {
          code = e.code
        }
      })
      queryRegion({superId: code}).then((response) => {
        this.companyRegionArr = response.data
      })
    },
    handleChangeCompanyStreet(val){
      this.form.companyStreets = undefined
      this.companyStreetArr = []
      let code
      this.companyRegionArr.forEach(e => {
        if (e.cityName == val) {
          code = e.code
        }
      })
      queryRegion({superId: code}).then((response) => {
        this.companyStreetArr = response.data
      })
    },
    handleChangeCityVal(val) {
      this.form.street = undefined
      this.form.region = undefined
      let code
      console.log(val, '我是市获取到的', this.cityArr, 22222)
      this.cityArr.forEach(e => {
        if (e.cityName == val) {
          code = e.code
        }
      })
      console.log(code, '我应该优质啊')
      queryRegion({superId: code}).then((response) => {
        this.regionArr = response.data
      })
    },
    handleChangeCompanyProviceVal(val) {
      if (val) {
        let code
        this.provinceArr.forEach(e => {
          if (e.cityName == val) {
            code = e.code
          }
        })
        queryRegion({superId: code}).then((response) => {
          this.companyCityArr = response.data
          if (this.form.companyCity) {
            let city
            this.companyCityArr.forEach(e => {
              if (e.cityName == this.form.companyCity) {
                city = e.code
              }
            })
            queryRegion({superId: city}).then((response) => {
              this.companyRegionArr = response.data
              if (this.form.companyRegion) {
                let region
                this.companyRegionArr.forEach(e => {
                  if (e.cityName == this.form.companyRegion) {
                    region = e.code
                  }
                })
                console.log(region, '我是返回的region')
                queryRegion({superId: region}).then((response) => {
                  this.companyStreetArr = response.data
                })
              }
            })
          }
        })
      }
    },
    handleChangeProviceVal(val) {
      if (val) {
        let code
        this.provinceArr.forEach(e => {
          if (e.cityName == val) {
            code = e.code
          }
        })
        queryRegion({superId: code}).then((response) => {
          this.cityArr = response.data
          if (this.form.city) {
            let city
            this.cityArr.forEach(e => {
              if (e.cityName == this.form.city) {
                city = e.code
              }
            })
            queryRegion({superId: city}).then((response) => {
              this.regionArr = response.data
              if (this.form.region) {
                let region
                this.regionArr.forEach(e => {
                  if (e.cityName == this.form.region) {
                    region = e.code
                  }
                })
                console.log(region, '我是返回的region')
                queryRegion({superId: region}).then((response) => {
                  this.streetArr = response.data
                })
              }
            })
          }
        })
      }

    },
    getPositionList() {
      let params = {
        meetingId: localStorage.getItem('meetId'),
        roleType: 2
      }
      getPositionList(params).then(res => {
        console.log(res, '我是返回的res')
      })
    },
    getGuestRole() {
      let params = {
        meetingId: localStorage.getItem('meetId')
      }
      getGuestRole(params.meetingId).then(res => {
        this.types = res.data
        this.volunteersId = res.data[3].id
      })
    },
    handleChangeGoProvinceVal() {
      this.mergeGuestUser.goCity = ''
      this.cityArr = []
      let value = ''
      this.provinceArr.forEach(e => {
        if (e.cityName == this.mergeGuestUser.goProvince) {
          value = e.code
        }
      })
      queryRegion({superId: value}).then((response) => {
        this.cityArr = response.data
      })
    },
    handleChangeToProvinceVal() {
      this.mergeGuestUser.toCity = ''
      this.cityArr = []
      let value = ''
      this.provinceArr.forEach(e => {
        if (e.cityName == this.mergeGuestUser.toProvince) {
          value = e.code
        }
      })
      queryRegion({superId: value}).then((response) => {
        this.cityArr = response.data
      })
    },
    handleChangeStaffType(val) {
      let arr = []
      if (this.role == 2) {
        arr = this.staffTypeArr.filter(n => {
          return val == n.id
        })
        if (val == 48) {
          this.companyTypeArr = [
            {
              label: "中国科协-机关部门",
              value: 5,
            },
            {
              label: "中国科协-直属单位",
              value: 6,
            }]
        } else {
          this.companyTypeArr = [
            {
              label: "政府机关",
              value: 8,
            }, {
              label: "中国科协-机关部门",
              value: 5,
            },
            {
              label: "中国科协-直属单位",
              value: 6,
            },
            {
              label: "省科协",
              value: 9,
            }, {
              label: "省级科协",
              value: 10,
            }, {
              label: "市科协",
              value: 11,
            }, {
              label: "市级科协",
              value: 12,
            }, {
              label: "国家级学会",
              value: 13,
            }, {
              label: "省级学会",
              value: 14,
            }, {
              label: "科研机构",
              value: 15,
            }, {
              label: "高校",
              value: 16,
            }, {
              label: "医院",
              value: 17,
            }, {
              label: "投融资机构",
              value: 18,
            }, {
              label: "企业",
              value: 19,
            }, {
              label: "其他",
              value: 20,
            }
          ]
        }
        selectByPosition({positionId: val}).then(res => {
          if (res.data && res.data.length == 0) {
            this.stepList = [{
              label: "报名",
              value: 0
            }, {
              label: "选择身份",
              desc: 1
            }, {
              label: "提交",
              desc: 2
            }]
          } else {
            this.stepList = [{
              label: "报名",
              desc: 0
            }, {
              label: "选择身份",
              desc: 1
            }, {
              label: "选择活动",
              desc: 2
            }, {
              label: "提交",
              desc: 3
            }]
          }
          res.data.forEach(e => {
            e.status = 0
            e.subMeetingId = e.id
            e.isMaster = 1
            e.subMeetingName = e.name
            e.haveAccompany = 0
          })
          this.collectionTrip = res.data.some(e => {
            return e.collectionTrip == 1
          })
          this.collectionStay = res.data.some(e => {
            return e.collectionStay == 1
          })
          console.log(this.collectionTrip, this.collectionStay)
          this.proDropData = res.data

          console.log(val)
        })
      } else {
        arr = this.mediaSourceArr.filter(n => {
          return val == n.id
        })
      }

      console.log(arr, '我是返回的arr')
      if (arr && arr.length > 0) {
        arr[0].inviteCode ? this.flagInvitation = true : this.flagInvitation = false
      }
    },
    checkCode() {
      let postData = {
        meetingId: localStorage.getItem('meetId'),
        roleId: this.role == 5 ? this.volunteersId : this.role,
        positionId: this.role == 2 ? this.form.staffType : this.form.mediaSource,
        invitation: this.form.invitationCodes || ''
      }
      gitCheckInvitation(postData).then(res => {
        if (!res.data) {
          this.form.invitationCodes = ''
          this.$message.error("邀请码错误,请重新输入");
        }
      })
    },
    //下一步
    handleChangeStep() {
      this.stepX++
      if (this.role == 1 && this.form.identity == 1 && this.form.haveAccompany == 1) {
        if (!this.followerDtoLists.every(e => e.followPhone != undefined && e.followName != undefined)) {
          this.stepX--
          this.active--
          this.$message.error('请补充完整随行人员信息')
        }
      }

      if (this.stepX == 1) {
        //填写个人信息时判断是否是为本人报名,如果是查询本人信息接口
        if (this.selected == 0) {
          this.getUserInfo()
        } else {
          this.subMeetingList()
          this.form = {...{}}
        }
        return;
      }
      if (this.stepX == 2 || this.stepX == 6) {
        console.log('我走进来了吗4')
        this.active++
      }
      if (this.stepX == 3) {  //第三步校验个人信息
        console.log('我走进来了吗1', this.$refs.loginForm)
        this.$refs.loginForm.validate((valid) => {
          console.log('我走进来了吗5')
          if (valid) {
            if (this.proDropData && this.proDropData.length == 0 && this.role == 2) {   //如果论坛为空数组直接报名
              this.handleSubmit()
              this.stepX--
              return
            }
            if (this.role == 4 || this.role == 5) {   //如果是媒体和支援者直接报名
              this.handleSubmit()
              this.stepX--
              return
            }
            this.active++
            console.log('我走进来了吗2')
          } else {
            console.log('我走进来了吗3')
            this.stepX--
          }
        })
        return
      }
      if (this.stepX == 4) {  //第四步校验是否已经报过论坛
        if (!this.proDropData.some(e => {
          return e.signUp != 0
        })) {
          this.stepX--
          this.$message.error("用户需要至少报名一个论坛");
          return
        }
        if (this.role == 2) {   //行程住宿都未开启
          this.stepX--
          this.handleSubmit()
          return;
        }
        // if (!this.collectionTrip && !this.collectionStay&&this.role==2) {   //行程住宿都未开启
        //   this.stepX--
        //   this.handleSubmit()
        //   return;
        // }
      }

      if (this.stepX == 5) {
        this.$refs.mergeGuestUserForm.validate((valid) => {
          if (valid) {
            if (!this.collectionStay && this.collectionTrip) {
              this.stepX--
              this.handleSubmit()
              return
            }
            this.active++
            console.log('我是校验通过')
          } else {
            this.stepX--
          }
        })
        return
      }
      if (this.stepX == 6) {
        console.log('我为什么没校验啊')
        this.$refs.guestUserSchedule.validate((valid) => {
          if (valid) {
            console.log('我是校验通过', valid,)
            this.stepX--
            this.active--
            this.handleSubmit()
          } else {
            this.active--
            this.stepX--
          }
        })
        return
      }
      if (this.stepX == 4 && this.role == 2) {
        if (!this.collectionTrip && this.collectionStay) {
          this.stepX++
          this.active++
        }
      }

    },
    handleChangePrevStop() {
      this.stepX--
      console.log(this.stepX, this.active, '我是现在的步数11')
      if (this.stepX == 1 || this.stepX == 2 || this.stepX == 4 || this.stepX == 5) {
        this.active--
      }
      if (!this.collectionTrip && this.collectionStay == 1 && this.stepX == 4) {
        this.stepX--
        // this.active --
      }
    },
    //上一步
    handleChangeSelectVal(item) {
      if (this.stepX == 0) {
        this.selected = item.value
      } else {
        item.type == 1 ? this.roleSelected = 0 : item.type == 2 ? this.roleSelected = 1 : item.type == 4 ? this.roleSelected = 2 : item.type == 5 ? this.roleSelected = 3 : ''
        this.role = item.type
      }
    },
    //提交
    handleSubmit() {
      let proDropList = []
      if (this.proDropData) {
        proDropList = this.proDropData.filter(e => {
          return e.signUp == 1
        })
      }
      if (this.form.identity == 0) {
        proDropList.forEach(item => {
          item.conflictStatus = 1
          item.haveAccompany = 0
          item.isConflict = 0
          item.isMaster = 0
          item.masterIsAcademician = this.form.masterIsAcademician
          item.subMeetingId = item.id
          item.subMeetingName = item.name
        })
      }
      if (this.form.province) {
        this.provinceArr.forEach(e => {
          if (this.form.province == e.cityName) {
            this.form.provinceId = e.code
          }
        })
      }
      if (this.form.city) {
        this.cityArr.forEach(e => {
          if (this.form.city == e.cityName) {
            this.form.cityId = e.code
          }
        })
      }
      if (this.form.region) {
        this.regionArr.forEach(e => {
          if (this.form.region == e.cityName) {
            this.form.regionId = e.code
          }
        })
      }
      if (this.form.street) {
        this.streetArr.forEach(e => {
          if (this.form.street == e.cityName) {
            this.form.streetId = e.code
          }
        })
      }

      if (this.form.companyProvince) {
        this.provinceArr.forEach(e => {
          if (this.form.companyProvince == e.cityName) {
            this.form.companyProvinceId = e.code
          }
        })
      }
      if (this.form.companyCity) {
        this.cityArr.forEach(e => {
          if (this.form.companyCity == e.cityName) {
            this.form.companyCityId = e.code
          }
        })
      }
      if (this.form.companyRegion) {
        this.companyRegionArr.forEach(e => {
          if (this.form.companyRegion == e.cityName) {
            this.form.companyRegionId = e.code
          }
        })
      }
      if (this.form.companyStreets) {
        this.companyStreetArr.forEach(e => {
          if (this.form.companyStreets == e.cityName) {
            this.form.companyStreetsId = e.code
          }
        })
      }
      if (this.identity == 0) {
        this.followerDtoLists[0].masterIsAcademician = this.form.masterIsAcademician
      }
      let params = {
        enrollWay: this.selected == 0 ? 1 : 0,
        followerDtoLists: this.followerDtoLists && this.followerDtoLists.length == 0 ? [] : this.followerDtoLists,
        guestUserId: this.form.guestUserId,
        guestUserInfoId: this.form.guestUserInfoId,
        isImport: 0,   //0手动填写的信息，1选择的导入方式
        identity: this.form.identity,
        staffType: this.form.staffType,
        isStay: this.guestUserScheduleForm.isNeedHotel,
        joinType: this.form.joinType,
        mediaType: this.form.mediaType,
        mediaSource: this.form.mediaSource,
        meetingName: this.form.meetingName,
        meetingId: localStorage.getItem('meetId'),
        masterGuestUserId: this.form.masterGuestUserId,       //主宾id
        masterName: this.form.masterName, //主宾姓名
        masterPhone: this.form.masterPhone, //主宾电话
        role: this.role,
        pressCard: this.form.pressCard,
        volunteersType: this.form.volunteersType,
        applySource: 2,
        status: 0,
        subMeetingList: proDropList || [],
        guestUserSchedule: {
          goTransport: this.mergeGuestUser.goTransport,
          goFlight: this.mergeGuestUser.goFlight,
          sourceAddress: this.mergeGuestUser.goProvince + '-' + this.mergeGuestUser.goCity,
          arriveAddress: this.mergeGuestUser.arriveAddress,
          goArriveTime: this.mergeGuestUser.goArriveTime,
          goNeedAirport: this.mergeGuestUser.goNeedAirport,
          toTransport: this.mergeGuestUser.toTransport,
          toFlight: this.mergeGuestUser.toFlight,
          toDepartAddress: this.mergeGuestUser.toProvince + '-' + this.mergeGuestUser.toCity,
          toArriveAddress: this.mergeGuestUser.toArriveAddress,
          toDepartTime: this.mergeGuestUser.toDepartTime,
          toNeedAirport: this.mergeGuestUser.toNeedAirport,
          isNeedTrip: this.mergeGuestUser.goProvince ? 1 : 0,
          isNeedHotel: this.guestUserScheduleForm.isNeedHotel || 0,
          inHotelTime: this.guestUserScheduleForm.inHotelTime,
          outHotelTime: this.guestUserScheduleForm.outHotelTime,
          roomType: this.guestUserScheduleForm.roomType,
          stayDays: this.guestUserScheduleForm.stayDays
        },
        mergeGuestUser: {
          province: this.form.province,
          provinceId: this.form.provinceId,
          city: this.form.city,
          cityId: this.form.cityId,
          region: this.form.region,
          regionId: this.form.regionId,
          streets: this.form.street,
          streetsId: this.form.streetId,
          companyProvinceId: this.form.companyProvinceId,
          companyCityId: this.form.companyCityId,
          companyRegionId: this.form.companyRegionId,
          companyStreetsId: this.form.companyStreetsId,
          companyProvince: this.form.companyProvince,
          companyCity: this.form.companyCity,
          companyRegion: this.form.companyRegion,
          companyStreets: this.form.companyStreets,
          avatar: this.form.avatar,
          academicianType: this.academicianType.toString(),
          cardType: this.form.cardType,
          company: this.form.company,
          companyType: this.form.companyType,
          guestUserId: this.form.guestUserId,
          idCardNo: this.form.idCardNo,
          isAcademician: this.form.isAcademician,
          mobile: this.form.mobile,
          post: this.form.post,
          postLevel: this.form.postLevel,
          postTitle: this.form.postTitle,
          sex: this.form.sex,
          name: this.form.realName,

        },
      }
      let search = $deepcopy(params)
      guestEnroll(search).then(res => {
        if (res.code == 200) {
          this.dialogMyInfoVisible = true
          if (!this.qrFlag) {
            this.qrFlag = true
            this.qrcodeRender()
          }
        } else {
          this.$message.warning(res.msg)
        }
      })

      console.log('我是校验通过', this.form, this.mergeGuestUser, this.guestUserScheduleForm, '我是三个表单的值 ')
    },
    handleBackToHome() {
      this.$router.push({path: '/'})
    },
    //根据手机号查询主宾信息
    getMaster() {
      var regExp = new RegExp("^1[35789]\\d{9}$");
      if (regExp.test(this.form.masterPhone)) {
        let params = {
          meetingId: localStorage.getItem('meetId'),
          masterPhone: this.form.masterPhone
        }
        getGuestKeyword(params).then(res => {
          if (res.code == 200 && res.data.length > 0) {
            let data = {
              meetingId: localStorage.getItem('meetId'),
              masterPhone: res.data[0].id
            }
            getGuestUserInfo(data).then(info => {
              // 不知道是哪里代码把form 响应式弄没了
              this.$set(this.form, 'masterName', info.data.realName)
              this.$set(this.form, 'masterIsAcademician', info.data.isAcademician)
              // this.form.masterName = info.data.realName
              this.form.masterGuestUserId = info.data.id //  主宾id
              // this.form.masterIsAcademician = info.data.isAcademician
              // info.data.meetings.forEach(e=>{
              //   e.subMeetingName = e.name
              //   e.subMeetingId = e.id
              // })
              // this.proDropData = info.data.meetings
              this.subMeetingList(1)
            })
          }
          console.log(res, '我是返回的res')
        })
      } else {
        this.form.masterPhone = ''
        this.$message.error("请输入正确格式的手机号");
      }

    },
    handleBlurMobile(val) {
      let phone = val.target.value
      if (!mobile.test(phone)) {
        this.$message.error('请输入正确格式的手机号')
        val.target.value = ''
      }
    },
    getUserInfo() {
      let params = {
        mobile: localStorage.getItem('mobile')
      }
      getUserInfo(params).then(res => {
        if (res.code == 200) {
          this.form = {...res.data}
          this.form.street = res.data.streets
          this.form.streetId = res.data.streetsId
          this.guestUserScheduleForm = {...res.data.guestUserSchedule}
          this.followerDtoLists = [
            {
              followName: undefined,
              followPhone: undefined,
            }
          ]
          this.subMeetingList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;

  .top {
    height: 533px;
    width: 100%;
    min-width: 1200px;
  }

  .sign-main {
    flex: 1;
    width: 100%;
    background: linear-gradient(90deg, #1B1E82 0%, #2F36C0 51%, #1D2087 100%);

    .main-box {
      //position: absolute;
      margin: 0 auto;
      margin-top: -54px;
      width: 1024px;
      background: #FFFFFF;
      min-height: 683px;
      padding: 40px 50px 66px;

      .box-top {
        height: 86px;
        display: flex;
      }

      .left {
        width: 15px;
        height: 86px;
      }

      .right {
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

      }

      .en-text {
        font-size: 40px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #3771D9;
        line-height: 45px;
      }

      .chinese-text {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 42px;
      }
    }
  }

  .form {
    margin-top: 30px;

    .form-tips {
      margin-left: 41px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 25px;
      margin-bottom: 26px;
    }

    .pt_241 {
      padding-left: 241px;
      padding-right: 241px;

    }

    .form-main {
      padding: 0 241px;
    }

    .pt_50 {
      padding: 30px 50px;
    }
  }

  .footer-text {
    margin: 60px auto 78px auto;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    line-height: 25px;
    letter-spacing: 2px;
  }

  .enrollWay {
    display: flex;
    flex-direction: column;


  }

  .back_home {
    position: fixed;
    top: 672px;
    right: 76px;

    img {
      width: 102px;
      height: 102px;
    }

  }

  .success-png {
    width: 125px;
    height: 105px;
    margin: 0 auto;
    display: block;
  }

  .success-text {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3771D9;
    line-height: 28px;
    margin-bottom: 46px;
    margin-top: 24px;
  }

  ::v-deep {
    .required {
      .el-form-item__label:before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
      }
    }

    .qrcode_val {
      img {
        margin: 30px auto;

      }

    }

    .el-input-group__append, .el-input-group__prepend {
      background-color: #FFFFFF;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3771D9;
      line-height: 24px;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-dialog__title {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3771D9;
      line-height: 28px;
      display: block;
      text-align: center;
    }

    .follow-item {
      .el-input {
        width: 248px;
      }

      .follower-input {

      }

      .el-form-item__content {
        display: flex;
        //align-items: center;
        //justify-content: space-between;
      }

    }

    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .el-checkbox {
      margin-right: 24px;
    }

    .el-checkbox:last-of-type {
      margin-right: 0;
    }

    .el-form-item {
      display: flex;
      align-items: center;
    }

    .el-radio__inner::after {
      width: 8px;
      height: 8px;
    }

    .el-form-item__content {
      flex: 1;
    }

    .el-select {
      width: 100%;
      //margin-left: 18px;
    }

    .el-form-item__label {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 25px;
      padding-right: 30px;
      width: 184px;
    }

    .el-step__line {
      height: 1px;
      background: #3771D9;
      top: 19px;
    }

    .el-step__head.is-success {
      color: #3771D9;
      border-color: #3771D9;
    }

    .is-process {
      border: none;

      .el-step__icon.is-text {
        background: #3771D9;
        color: #fff;
      }
    }

    .el-radio__inner {
      width: 24px;
      height: 24px;
    }

    .el-step__title {
      margin-top: 8px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 25px;
      width: 72px;
      text-align: center;
    }

    .el-step__icon {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: flex;
    }

    .el-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .rank-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #979797;
    padding: 0 24px;
    margin-top: 30px;

    .select-status {
      width: 32px;
      height: 32px;
    }
  }

  .have-friend {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3771D9;
    line-height: 28px;
    margin-left: 168px;
    margin-bottom: 22px;
  }

  .avatar-tips {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9B9B9B;
    line-height: 22px;
  }

  .carame {
    width: 50px;
    height: 40px;
  }

  .avatar {
    width: 98px;
    height: 78px;
  }

  .avatar-uploader {
    width: 98px !important;
    height: 78px;
    border: #979797 1px dashed;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .forum {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(155, 155, 155, 0.34);
  }

  .forum-box:last-child {
    border-bottom: 1px solid rgba(155, 155, 155, 0.34);
  }

  .forum-left {
    display: flex;
    flex-direction: column;

    .left-name {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4A4A4A;
      line-height: 25px;
      margin-bottom: 12px;
    }

    .left-time {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 22px;
      margin-bottom: 12px;
    }

    .left-place {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 22px;
    }
  }

  .forum-right {
    width: 112px;
    height: 40px;
    background: rgba(3, 66, 178, 0.83);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .unForum {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #2E63BF;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2E63BF;
    line-height: 22px;
  }

  .master-search {

  }

  .footer-btn {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;

    .btn-prev {
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #3771D9;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3771D9;
      line-height: 22px;
    }

    .dia-footer {
      margin-top: 0;
    }

    ::v-deep {
      .el-button {
        width: 206px;
        height: 56px;
      }
    }
  }

  .info-top {
    display: flex;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #3771D9;
    line-height: 48px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(155, 155, 155, 0.41);;
    align-items: center;
    justify-content: center;

    img {
      margin-left: 10px;
      width: 50px;
      height: 50px;
    }

  }

  .my-info {
    display: flex;

    .left {
      width: 50%;
      text-align: center;

      .left-title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #3771D9;
        line-height: 30px;
      }

      img {
        margin: 30px 0;
        width: 200px;
        height: 200px;
      }

      .left-info {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D0021B;
        line-height: 33px;
      }
    }

    .right {
      width: 50%;
      text-align: center;

      .right-title {
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #3771D9;
        line-height: 30px;
      }
    }
  }

  .hotel-tips {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D0021B;
    line-height: 22px;
    margin-left: 183px;
  }

  .trip-tips {
    font-size: 17px;
    color: #D0021B;
    width: 472px;
    margin-left: 41px;
  }

  .formMobile {
    position: relative;

    .mobile {
      position: absolute;
      left: 0px;
      bottom: -8px;
      color: red;
    }
  }
}
</style>
