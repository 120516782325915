import {post, get, getRole, getRoleType, postMaster} from "./request";

// 发送验证码
export const getValidCode = (params) => get("/api/getValidCode", params);
//登录
export const loginIn = (params) => post("/api/login", params);
//角色
export const getGuestRole = (params) => getRole("/tenant/guest/role/query", params);
//工作人员获取工作组
export const getPositionList = (params) => getRoleType("/guest/position/list", params);
//获取省
export const queryRegionList = (params) => get("/api/region/queryRegionList", params);
//获取区
export const queryRegion = (params) => get("/api/region/queryRegion4List", params);
//获取媒体来源
export const queryPositionList = (params) => getRoleType("/guest/position/list", params);
//上传图片
export const uploadImg = (params) => post("/api/upload/img", params);
//获取个人信息
export const getUserInfo = (params) => get("/api/guest/queryByMobile", params);
//查询分论坛名称
export const subMeetingList = (params) => get("/api/guest/subMeetingList", params);
//查询论坛是否冲突
export const guestConflict = (params) => post("/api/guest/conflict", params);
//校验邀请码
export const gitCheckInvitation = (params) => get("/api/guest/checkInvitation", params);
//获取主宾id
export const getGuestKeyword = (params) => postMaster("/api/guest/keyword", params);
//获取主宾信息
export const getGuestUserInfo = (params) => postMaster("/api/guest/userInfo", params);
//提交报名信息
export const guestEnroll = (params) => post("/api/guest/enroll", params);
//通过posttionid获取工作组是否有list
export const selectByPosition = (params) => get("meeting/agenda/selectByPosition", params);
