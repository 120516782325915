<template>
  <div class="index">
    <img class="header-img" src="../assets/sign/banner@2x.png">
    <div class="header-right">
<!--      <div class="lange" v-for="(item,index) in langList" :key="index" @click="changeLangeVal(item)"-->
<!--           :class="selected==index?'selected':''">-->
<!--        <div class="item">{{ item.label }}</div>-->
<!--      </div>-->
      <div class="login" @click="loginIn">登录/注册</div>
      <img @click="handleClickMyInfo" class="my" src="../assets/sign/my@2x.png" alt="">
    </div>
    <div class="btns">
      <div class="sign-btn" @click="handleClickToSign(1)">
        我要报名
      </div>
      <div class="sign-btn" @click="handleClickToSign(2)">
        年会直播
      </div>
      <div class="sign-btn" @click="handleClickToSign(3)">
        百大报告
      </div>
    </div>

    <el-dialog
        title=""
        class="info"
        :close-on-click-modal="false"
        :visible.sync="dialogMyInfoVisible"
        width="800px"
        :before-close="()=>{this.dialogMyInfoVisible = false}">
      <div class="info-top">
        <div>您已报名成功，请至移动端查看报名详情！</div>
        <img src="../assets/sign/dianji.png" alt="">
      </div>
      <div class="my-info">
        <div class="left">
          <div class="left-title">第二十四届中国科协年会移动端二维码</div>
          <div id="qrcode" class="qrcode_val"></div>
<!--          <img src="../assets/sign/success.png" alt="">-->
          <div class="left-info">为方便您查看更多报名详情，请您使用手机扫描上方二维码进入移动端个人中心查看</div>
        </div>
        <div class="right">
          <div class="right-title">移动端使用说明如下图</div>
          <img style="width: 314px; height: 278px;" src="../assets/sign/my_info.png" alt="">
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="登录/注册"
        :visible.sync="dialogLoginVisible"
        width="510px"
        :close-on-click-modal="false"
        :before-close="handleClose">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="form">
        <el-form-item prop="username">
          <el-input
              placeholder="请输入手机号"
              auto-complete="off"
              prefix-icon="el-icon-mobile-phone"
              v-model="loginForm.username">
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              auto-complete="off"
              placeholder="请输入验证码"
              prefix-icon="el-icon-unlock"
              v-model="loginForm.code">
            <template slot="append">
              <el-button :disabled="!gcode" class="get-code" @click="getVisCode"
              >
                <div>
                  {{ gcode ? '获取验证码' : time_count + 's' }}
                </div>
              </el-button
              >
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div @click="handleLoginIn" class="dia-footer">
        登 录
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getValidCode, loginIn} from '../api/index'
import QRCode from "qrcodejs2";

export default {
  name: "login",
  data() {
    return {
      langList: [
        {label: '中文', value: 0},
        {label: 'EN', value: 1}
      ],
      dialogLoginVisible: false,
      dialogMyInfoVisible: false,
      selected: 0,
      loginForm: {
        type: 1
      },
      qrFlag:false,
      time_count: 60,
      timer: null,
      gcode: true,
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "用户名不能为空"}
        ],
        code: [{required: true, trigger: "change", message: "验证码不能为空"}]
      }

    };
  },
  watch: {},
  mounted() {
    localStorage.setItem('meetId', process.env.VUE_APP_MEED_ID) // 本地
    // localStorage.setItem('meetId', '1518826881493766146') // 线上 测试环境
  },
  methods: {
    // changeLangeVal(item) {
    //   if (item.value == 1) {
    //     this.$message.warning('暂未开放')
    //   }
    // },
    loginIn() {
      this.dialogLoginVisible = true
      clearInterval(this.timer);
      this.timer = null
      this.gcode = true
      this.time_count = 60
    },
    handleClickMyInfo() {
      if (!localStorage.getItem('Authorization')) {
        this.dialogLoginVisible = true    //未登录弹登录弹框
      } else {
        this.dialogMyInfoVisible = true
        if(!this.qrFlag){
          this.qrFlag = true
          this.qrcodeRender()
        }
      }

    },
    qrcodeRender() {
      //获取当前的ip和端口，不要写死
      this.$nextTick(()=>{
        let qrcode = new QRCode('qrcode', {
          text: 'http://castmeeting.h5.2022.castmeeting.cn/#/', // url
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        });
        console.log(qrcode,1111)
      })
    },
    handleClickToSign(val) {  //报名
      if(val==1){
        if (!localStorage.getItem('Authorization')) {
          this.dialogLoginVisible = true
        } else {
          this.$router.push({path: "/sign"});
        }
      }else if(val === 2){
        this.$router.push({path: "/live"});
      } else if( val === 3) {
        window.open('http://learning_web.castmeeting.cn/')
      }

    },
    handleClose() {
      this.dialogLoginVisible = false
      delete this.loginForm.username
      delete this.loginForm.code
      this.$forceUpdate()
    },
    handleLoginIn() {
      this.$refs.loginForm.validate(valid => {
        console.log(this.loading, 222222)
        if (valid) {
          loginIn(this.loginForm).then(res => {
            if (res.code == 200) {
              this.dialogLoginVisible = false
              localStorage.setItem('Authorization', 'Bearer ' + res.data)
              localStorage.setItem('mobile', this.loginForm.username)
              this.$router.push({path: "/sign"});
              this.$message({message: '登录成功', type: "success"});
            } else {
              this.$message({message: res.msg, type: "warning"});
            }
          })
        }
      })
    },
    getCode() {
      let that = this;
      that.gcode = false;
      this.getValidCode();
      console.log("我应该走进来了啊", that.gcode);
      if (!that.timer) {
        that.timer = setInterval(() => {
          if (that.time_count > 0) {
            that.gcode = false;
            that.time_count--;
          } else {
            that.gcode = true;
            clearInterval(that.timer);
            that.timer = null;
            that.time_count = 60;
          }
        }, 1000);
      }
    },
    getValidCode() {
      getValidCode({
        meetingId: localStorage.getItem('meetId'),
        mobile: this.loginForm.username,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res.data, "我是返回的验证码数据");
        } else {
          this.$message({message: res.msg, type: "warning"});
        }
      });
    },
    getVisCode() {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/
      if (reg.test(this.loginForm.username)) {
        this.gcode = true;
        this.getCode();
      } else {
        this.$message({message: "请输入正确格式的手机号", type: "warning"});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  position: relative;
    //background-image: url("../assets/sign/banner@2x.png");
    //height: 100%;
    //background-size: cover;
  .my-info {
    display: flex;
    .left {
      width: 50%;
      text-align: center;
      .left-title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #3771D9;
        line-height: 30px;
      }

      img {
        margin: 30px 0;
        width: 200px;
        height: 200px;
      }

      .left-info {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D0021B;
        line-height: 33px;
      }
    }

    .right {
      width: 50%;
      text-align: center;

      .right-title {
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #3771D9;
        line-height: 30px;
      }
    }
  }
  .info-top{
    display: flex;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #3771D9;
    line-height: 48px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid  rgba(155, 155, 155, 0.41);;
    align-items: center;
    justify-content: center;
    img{
      margin-left: 10px;
      width: 50px;
      height: 50px;
    }

  }
  .header-img {
    width: 100%;
    min-height: 1080px;
    min-width: 1400px;
  }

  .selected {
    background: #FFFFFF;
    color: #0710B1 !important;
    border-radius: 4px 0px 0px 4px !important;;
  }

  .form {
    margin-top: 34px;
  }


  .header-right {
    position: absolute;
    top: 56px;
    right: 0;
    display: flex;
    align-items: center;
    min-width: 500px;
  }

  .lange {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    border: 1px solid #FFFFFF;
    border-radius: 0px 4px 4px 0px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 33px;
  }

  .login {
    margin-left: 30px;
    font-size: 22px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FEFFFF;
    line-height: 37px;
  }

  .my {
    width: 35px;
    height: 35px;
    margin-left: 30px;
  }
  .btns{
    position: absolute;
    top: 734px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    min-width: 1200px;
    //top: 734px;
    .sign-btn {
      width: 334px;
      height: 60px;
      background: #CF9836;
      border-radius: 20px;
      font-size: 30px;
      font-weight: 600;
      color: #FEFFFF;
      line-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sign-btn + .sign-btn {
      margin-left: 60px;
    }
  }


  .info {
    ::v-deep {
      .el-dialog {
        margin: auto !important;
        margin-top: 22vh !important;
      }
    }
  }

  ::v-deep {
    .qrcode_val{
      img{
        margin: 30px auto;

      }

    }
    .el-dialog__title {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3771D9;
      line-height: 28px;
      display: block;
      text-align: center;
    }

    .el-dialog__headerbtn {
      font-size: 24px;
    }

    .el-input__inner {
      border-right: none;
      border-left: none;
      border-top: none;
      border-bottom: 1px solid #DCDFE6;
    }

    .el-input-group__append {
      width: 114px;
      background-color: #fff;
      border: none;
    }

    .get-code {
      width: 100%;
      height: 35px;
      background: #FFFFFF;
      border-radius: 16px;
      border: 1px solid #3771D9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3771D9;
      //line-height: 20px;
      margin: 0;
    }
  }
}
</style>
